.App {
    font-family: 'Web437 IBM BIOS';
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-style: normal;
    cursor: pointer;
  }

.nav {
  position: absolute;
  overflow: hidden;
  top: 15%;
}

.nav a {
  color: lightblue;
  text-decoration: none;
  padding: 8px 20px;
  font-size: 20px;
  align-items: center;

}

.nav ul {
  list-style-type: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} 

#contact_btn, #skills_btn, #projects_btn, #blogs_btn {
  padding: 0;
  border: none;
  background: none;
}
 

.hidden { display:none; }

.footer {
  height: 25px;  
  width: 100%;
  position: fixed;
  background: grey;
  color: #000000;
  border: 1px solid black;
  bottom: 0;
  overflow: hidden;
 }

 .footer p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 25px;
  text-align: center;

  /* Starting position */
     -moz-transform:translateX(100%);
     -webkit-transform:translateX(100%);  
     transform:translateX(100%);

/* Apply animation to this element */  
     -moz-animation: footer 30s linear infinite;
     -webkit-animation: footer 30s linear infinite;
     animation: footer 30s linear infinite;
}

/* #clock {
  position: absolute;
  text-align: center;
  background-color: #18ffff;
  right: 0;
  height: 25px;
  width: 100px;
  padding-inline: 5px;
  font-weight: bold;
  font-size: 16px;
  bottom: 0;
  background: grey;
  color: #000000;
  border: 1px solid black;
} */

@-moz-keyframes footer {
  0%   { -moz-transform: translateX(75%); }
  100% { -moz-transform: translateX(-75%); }
 }
 @-webkit-keyframes footer {
  0%   { -webkit-transform: translateX(75%); }
  100% { -webkit-transform: translateX(-75%); }
 }
 @keyframes footer {
  0%   { 
  -moz-transform: translateX(75%); /* Firefox bug fix */
  -webkit-transform: translateX(75%); /* Firefox bug fix */
  transform: translateX(75%);         
  }
  100% { 
  -moz-transform: translateX(-75%); /* Firefox bug fix */
  -webkit-transform: translateX(-75%); /* Firefox bug fix */
  transform: translateX(-75%); 
  }
 }

@media (max-width: 600px) {
  .nav {
    top: 0;
  }

  .nav li {
    display: inline;
  }

}