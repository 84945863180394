
#projects_window {
	position: absolute;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #FFFFFF;
  background-color: #0c0c0c;
  min-width: 300px;
  min-height: 300px;
}

.windowed {
  top: 90px;
  left: 100;
  width:calc(100% - 600px);
  height: calc(100% - 200px);
}

.fullscreen {
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 30px);
}

#titlebar {
  display: block;
  position: fixed;
  height: 28px;
  width: 100%;
  background: #0C0C0C;
  border-bottom-color: #FFFFFF;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.maximized #titlebar {
  width: 100%;
  padding: 0;
}

.maximized #window-title {
  margin-left: 12px;
}

#window-title {
  grid-column: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 4px;
  overflow: hidden;
  cursor: move;
  font-family: "Segoe UI", sans-serif;
  font-size: 14px;
  color: #ffffff;

  background-color: #000000;
  opacity: 0.8;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(45deg, #ffffff 0, #ffffff 1px, #000000 0, #000000 50%);

}

#window-title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #0C0C0C;
  padding-inline: 10px;
  line-height: 1.5;
}

#window-controls {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(2, 46px);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

#window-controls .button {
  grid-row: 1 / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #FFFFFF;
  width: 100%;
  height: 100%;
  border-left-color: #FFFFFF;
  border-left-style: solid;
  border-left-width: 1px;
}

#close-button {
  grid-column: 2;
}

#max-button {
  grid-column: 1;
}

#window-controls .button {
  user-select: none;
}
#window-controls .button:hover {
  background: rgba(255,255,255,0.1);
}
#window-controls .button:active {
  background: rgba(255,255,255,0.2);
}

#close-button:hover {
  background: #E81123 !important;
}
#close-button:active {
  background: #F1707A !important;
}
#close-button:active .icon {
  filter: invert(1);
}

/* #close_btn {
  position: absolute;
  padding: 4px 12px;
  outline:none;
  top: 0;
  right: 0;
  color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #0c0c0c;
  background-color: #000000;
}

#close_btn:hover {
  color:  #0c0c0c;
  background-color: #FFFFFF;
}

#top_border_projects {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 100%;
  cursor: move;
  background-color: #000000;
}

#top_border_projects p {
	margin: 0;
	font-size: 14px;
	top: 2px;
	left: 5px;
	position: fixed;
	color: #ffffff;
} */

#content {
  position: absolute;
  top: 30px;
  left:  4px;
  width: 98%;
  height: 92%;
  background-color: #0c0c0c;
  border-style: solid;
  border-width: 1px;
  border-color: darkgrey;
  overflow-y: scroll;
}

/* @media (max-width: 600px) {
  #projects_window {
    top: 50px;
    left: 10%;
    width:calc(100% - 200px);
    height: calc(100% - 200px);
    min-width: 300px;
    min-height: 300px;
    cursor:pointer;
    top: 20%;
  }
} */

@media (max-width: 600px) {
  #projects_window {
    max-width: 100%;
    max-height: calc(100% - 30px);
    min-width: 300px;
    min-height: 300px;
    cursor:pointer;
  }
}
