
#blogs_window {
    position: absolute;
    display: flex;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    border-color: #C7C7C7;
    background-color: #0c0c0c;
    min-width: 300px;
    min-height: 300px;
    cursor:pointer;
    transition: all 0.2s ease;
  }

.windowed {
  position: fixed;
  top: 50px;
  left: 10%;
  width: 80%; /* Adjust as needed */
  height: 80vh; /* Adjust as needed */
  max-width: calc(100% - 300px); /* Adjust as needed */
  max-height: calc(100% - 100px); /* Adjust as needed */
  overflow: auto;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 30px);
  margin: 0;
  padding: 0;
  overflow: auto;
}
  
  #titlebar {
    display: block;
    position: fixed;
    height: 28px;
    width: 100%;
    background: #0C0C0C;
    border-bottom-color: #C7C7C7;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  
  .maximized #titlebar {
    width: 100%;
    padding: 0;
  }
  
  .maximized #window-title {
    margin-left: 12px;
  }
  
  #window-title {
    grid-column: 1;
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-top: 4px;
    overflow: hidden;
    cursor: move;
    font-family: "Segoe UI", sans-serif;
    font-size: 14px;
    color: #C7C7C7;

    background-color: #000000;
    opacity: 0.8;
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(45deg, #C7C7C7 0, #C7C7C7 1px, #000000 0, #000000 50%);

  }
  
  #window-title span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #0C0C0C;
    padding-inline: 10px; 
    line-height: 1.5;
  }
  
  #window-controls {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(2, 46px);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  
  #window-controls .button {
    grid-row: 1 / span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #C7C7C7;
    width: 100%;
    height: 100%;
    border-left-color: #C7C7C7;
    border-left-style: solid;
    border-left-width: 1px;
  }
  
  #close-button {
    grid-column: 2;
  }
  
  #max-button {
    grid-column: 1;
  }
  
  #window-controls .button {
    user-select: none;
  }
  #window-controls .button:hover {
    background: rgba(255,255,255,0.1);
  }
  #window-controls .button:active {
    background: rgba(255,255,255,0.2);
  }
  
  #close-button:hover {
    background: #E81123 !important;
  }
  #close-button:active {
    background: #F1707A !important;
  }
  #close-button:active .icon {
    filter: invert(1);
  }
  
  /* #close_btn {
    position: absolute;
    padding: 4px 12px;
    outline:none;
    top: 0;
    right: 0;
    color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #0c0c0c;
    background-color: #000000;
  }
  
  #close_btn:hover {
    color:  #0c0c0c;
    background-color: #FFFFFF;
  }
  
  #top_border_skills {
    top: 0;
    left: 0;
    height: 25px;
    width: 100%;
    cursor: move;
    background-color: #000000;
  }
  
  #top_border_skills p {
      margin: 0;
    position: absolute;
      font-size: 14px;
      top: 2px;
      left: 5px;
      color: #ffffff;
  } */
  
  #content_blogs {
    margin-top: 8%;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 6%;
    width: 100%;
    background-color: #C7C7C7;
  }
  
  ul.list {
    position: absolute;
    align-items: margin;
    margin: 0;
    padding: 0;
  }
  
  .list li {
    color: green;
    padding: 5px 0px;
    list-style-type: none;
  }

  /* @media (max-width: 900px) {
    #projects_window {
      top: 50px;
      left: 10%;
      width:calc(100% - 200px);
      height: calc(100% - 200px);
      min-width: 500px;
      min-height: 300px;
      cursor:pointer;
      top: 20%;
    }
  } */
  
  @media (max-width: 600px) {
    #blogs_window {
      max-width: 100%;
      max-height: calc(100% - 30px);
      min-width: 300px;
      min-height: 300px;
      cursor:pointer;
    }
  }
  