
#contact_window {
	position: absolute;
  display: flex;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #FFFFFF;
  background-color: #0c0c0c;
  min-width: 250px;
  min-height: 250px;
  transition: all 0.2s ease;
}

.windowed {
  top: 110px;
  left: 90px;
  width:calc(100% - 600px);
  height: calc(100% - 200px);
}

.fullscreen {
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 30px);
}

#titlebar {
  display: block;
  position: fixed;
  height: 28px;
  width: 100%;
  background: #0C0C0C;
  border-bottom-color: #FFFFFF;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.maximized #titlebar {
  width: 100%;
  padding: 0;
}

.maximized #window-title {
  margin-left: 12px;
}

#window-title {
  grid-column: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 4px;
  overflow: hidden;
  cursor: move;
  font-family: "Segoe UI", sans-serif;
  font-size: 14px;
  color: #ffffff;

}

#window-title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
}

#window-controls {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(2, 46px);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

#window-controls .button {
  grid-row: 1 / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #FFFFFF;
  width: 100%;
  height: 100%;
  border-left-color: #FFFFFF;
  border-left-style: solid;
  border-left-width: 1px;
}

#close-button {
  grid-column: 2;
}

#max-button {
  grid-column: 1;
}

#window-controls .button {
  user-select: none;
}
#window-controls .button:hover {
  background: rgba(255,255,255,0.1);
}
#window-controls .button:active {
  background: rgba(255,255,255,0.2);
}

#close-button:hover {
  background: #E81123 !important;
}
#close-button:active {
  background: #F1707A !important;
}
#close-button:active .icon {
  filter: invert(1);
}

#contact-body {
  padding: 12px;
  width: 100%;
  height: 100%;
  background-color: #0C0C0C;
  color: #FFFFFF;
  box-sizing: border-box;
  border-color: #FFFFFF;
  border-style: solid;
  border-width: 1px;
}

#contact-body p {
  padding: 5px;
}

#contact-form {
  width: calc(100% - 25px);
  height: calc(100% - 100px);
  align-self: center;
  align-items: center;
  background-color: #0C0C0C;
  border-style: solid;
  border-width: 1px;
  border-color: #FFFFFF;
  padding: 10px;

  background-image: repeating-linear-gradient(45deg, 
    #2f2f2f 10px,
    #2f2f2f 12px,
    transparent 12px,
    transparent 20px);

}

.form-group {
  padding: 5px;
}

.form-group input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background-color: #0C0C0C;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  resize: vertical;
}

.form-group input[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background-color: #0C0C0C;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  resize: vertical;
}

#mail-me {
  width: 150px;
  height: 40px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: #0C0C0C;
  border: #FFFFFF solid 1px;
  border-radius: 4px;

  background-image: repeating-linear-gradient(135deg, 
    #CDCDCD 10px,
    #CDCDCD 12px,
    transparent 12px,
    transparent 20px);
}

#mail-me:hover {
  border-color:  #33f536;
  background-image: repeating-linear-gradient(135deg, 
    #33f536 10px,
    #33f536 12px,
    transparent 12px,
    transparent 20px);
}

#mail-me b {
  height: 60%;
  width: 60%;
  padding-inline: 5px;
  border: #FFFFFF solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0C0C0C;
}

#content_contact {
  position: absolute;
  top: 30px;
  left:  4px;
  width: 98%;
  height: 92%;
  background-color: #0c0c0c;
  border-style: solid;
  border-width: 1px;
  border-color: darkgrey;
}

#content_contact p {
  color:  #ffffff;
  margin: 1px;
}

/* @media (max-width: 900px) {
  #projects_window {
    top: 50px;
    left: 10%;
    width:calc(100% - 200px);
    height: calc(100% - 200px);
    min-width: 500px;
    min-height: 300px;
    cursor:pointer;
    top: 20%;
  }
} */

@media (max-width: 600px) {
  #contact_window {
    max-width: 100%;
    max-height: calc(100% - 30px);
    min-width: 300px;
    min-height: 300px;
    cursor:pointer;
  }
}
