
:root {
  --top-custom: 0;
  --left-custom: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #232323;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "WotfardMedium";
  src: local("Wotfard"),
    url("./fonts/wotfard/wotfard-medium-webfont.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "WotfardRegular";
  src: local("Wotfard"),
    url("./fonts/wotfard/wotfard-regular-webfont.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "WotfardLight";
  src: local("Wotfard"),
    url("./fonts/wotfard/wotfard-light-webfont.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "BasedPixel";
  src: local("Based"),
    url("./fonts/based/Based-Regular.22ef1e41.woff2") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "WotfardSemi";
  src: local("Wotfard"),
    url("./fonts/wotfard/wotfard-semibold-webfont.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "WotfardBold";
  src: local("Wotfard"),
    url("./fonts/wotfard/wotfard-bold-webfont.ttf") format("truetype");
  font-weight: normal;
}